import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Merry Christmas!`}</strong></p>
    <p>{`Rest Day.`}</p>
    <p><em parentName="p">{`Yes, we are closed today.  We will reopen at 6:00am on Monday for class
ONLY AT THE VILLE.  There will be no 6:00am at East.  All other classes
on Monday are on as usual.`}</em></p>
    <p><strong parentName="p">{`*`}{`New Ville shirts and tanks have arrived! Check them out next time
you’re in.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar will Dawn from Sports Nutrition 2 Go in Cincinnati. Metabolic
Testing will be offered before and after the seminar as well!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      